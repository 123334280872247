<template>
  <a-card class="card" :bordered="false">
    <!-- 搜索框 -->
    <div class="table-page-search-wrapper">
      <a-form-model
        :model="queryParam"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-row>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="月份">
              <a-month-picker v-model="queryMonth" style="width: 200px;" format="YYYYMM" placeholder="请选择月份"></a-month-picker>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="货物名称">
              <a-input v-model.trim="queryParam['good_name']" placeholder="根据货物名称搜索"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col v-bind="GLOBAL.queryColSpan">
            <span class="table-page-search-submitButtons">
              <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
              <a-popover
                v-model="popVisible8"
                title="选择月份"
                trigger="click">
                <a slot="content">
                  <p><a-month-picker v-model="exportDate8" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                  <p style="text-align: right"><a-button type="primary" @click="handleSaveStockStatistic">确定</a-button></p>
                </a>
                <a-button type="primary">生成库存盘点</a-button>
              </a-popover>
              <a-popover
                v-model="popVisible"
                title="选择月份"
                trigger="click">
                <a slot="content">
                  <p><a-month-picker v-model="exportDate" style="width: 200px;" format="YYYYMM" placeholder="请选择月份"></a-month-picker></p>
                  <p style="text-align: right"><a-button @click="exportStockStatistic" class="ant-btn-query margin-right10">确定</a-button></p>
                </a>
                <a-button type="primary">导出</a-button>
              </a-popover>
              <a-popover
                v-model="popVisibleCheck"
                title="选择月份"
                trigger="click">
                <a slot="content">
                  <p><a-month-picker v-model="exportDateCheck" style="width: 200px;" format="YYYYMM" placeholder="请选择月份"></a-month-picker></p>
                  <p style="text-align: right"><a-button type="danger" @click="checkStockStatistic">确定</a-button></p>
                </a>
                <a-button type="danger">核对完成</a-button>
              </a-popover>
            </span>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <!-- 表格展示 -->
    <a-table
      row-key="id"
      size="small"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">编辑</a>
        <a @click="delRecord('row', record)">删除</a>
      </div>
      <div slot="statusSlot" slot-scope="text">
        <a-tag :color="text==='checked'?'green':'orange'"> {{text==='checked'?'已核对':'未核对'}}</a-tag>
      </div>
      <div slot="differentSlot" slot-scope="text, record">
        <a-popover placement="bottom">
          <template v-if="record.finance_num-record.business_num != 0">
            <a>{{ record.finance_num-record.business_num }}</a>
          </template>
          <template slot="content">
            <span v-for="(item, i) in record.differents" :key="i" style="display:block">
              {{ item.bill_order+'/'+item.good_name+'/'+item.good_spec+'/'+(item.bill_type==0?-1:1)*item.num+'/'+item.reason }}
            </span>
          </template>
        </a-popover>
      </div>
    </a-table>
  </a-card>
</template>
<script>
import {
  queryStockStatisticPage,
  exportStockStatistic,
  checkStockStatistic,
  saveStockStatistic
} from '@/api/wms/sale';
import { downloadExcel } from '@/api/common';
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      queryParam: {},
      queryMonth: null,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: `月份`,
          dataIndex: `month_date`
        },
        {
          title: `状态`,
          dataIndex: `status`,
          scopedSlots: { customRender: 'statusSlot' }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name'
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit'
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec'
        },
        {
          title: '进货去税',
          dataIndex: 'unit_price_tax'
        },
        {
          title: '开票数',
          dataIndex: 'invoice_num'
        },
        {
          title: '账面结余',
          dataIndex: 'finance_num'
        },
        {
          title: '账面金额',
          dataIndex: 'finance_amount',
          customRender: (text, record) => {
            return parseFloat(record.finance_num * record.unit_price_tax).toFixed(2)
          }
        },
        {
          title: '差异',
          dataIndex: 'different',
          scopedSlots: { customRender: 'differentSlot' }
        },
        {
          title: '出库数',
          dataIndex: 'sell_num'
        },
        {
          title: '库存结余',
          dataIndex: 'business_num'
        },
        {
          title: '在途数量',
          dataIndex: 'unarrive_num'
        },
        {
          title: '未发货数量',
          dataIndex: 'unsend_num'
        },
        {
          title: '实际库存',
          dataIndex: 'realy_num',
          customRender: (text, record) => {
            return record.business_num - record.unarrive_num + record.unsend_num
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        pageSizeOptions: ['20', '30', '50', '100'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      popVisible: false,
      exportDate: null,
      popVisibleCheck: false,
      exportDateCheck: null,
      popVisible8: false,
      exportDate8: null
    }
  },
  created() {
    // 页面加载数据
    this.searchInfo();
  },
  methods: {
    searchInfo() {
      this.loadData();
    },
    // 加载初始数据
    loadData() {
      if (this.queryMonth) {
        this.queryParam.month_date = this.queryMonth.format('YYYYMM')
      } else {
        this.queryParam.month_date = null
      }
      queryStockStatisticPage({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...this.queryParam
      }).then(res => {
        this.data = res.list;
        this.pagination.total = res.total;
      });
    },
    changeTable(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    // 库存盘点表（汇总表）
    exportStockStatistic() {
      if (this.exportDate) {
        exportStockStatistic({ 'month_date': this.exportDate.format('YYYYMM') }).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    checkStockStatistic() {
      if (this.exportDateCheck) {
        checkStockStatistic({ 'month_date': this.exportDateCheck.format('YYYYMM') }).then(res => {
          if (res) {
            this.$message.success('核对成功');
          }
        })
      } else {
        this.$message.warning('请选择核对月份');
      }
    },
    handleSaveStockStatistic() {
      if (this.exportDate8) {
        // this.queryParam['year'] = this.exportDate8.year();
        // this.queryParam['month'] = this.exportDate8.month() + 1;
        saveStockStatistic({ 'year': this.exportDate8.year(), 'month': this.exportDate8.month() + 1 }).then(res => {
          if (res) {
            this.$message.success('记录生成成功');
          }
        }).finally(_ => {
          this.popVisible8 = false;
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
